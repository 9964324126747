import { useState, useRef } from 'react';

const useAsyncReducer = (
  reducer,
  initialState = null,
  transformState = null,
  transformStateOnError = null
) => {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(initialState);

  const dispatch = async (action) => {
    const result = reducer(state, stateRef.current, action);
    if (typeof result.then === 'function') {
      try {
        const newData = await result;
        let newState = {};
        if (transformState && typeof transformState === 'function') {
          newState = transformState(stateRef.current, newData);
        } else {
          newState = result;
        }
        setState({ ...stateRef.current, ...newState });
        stateRef.current = { ...stateRef.current, ...newState };
      } catch (err) {
        let newState = {};
        if (
          transformStateOnError &&
          typeof transformStateOnError === 'function'
        ) {
          newState = transformStateOnError(stateRef.current, err);
        }

        setState({ ...stateRef.current, ...newState, _asyncError: err });
        stateRef.current = {
          ...stateRef.current,
          ...newState,
          _asyncError: err,
        };
      }
    } else {
      const newData = result;
      let newState;
      if (transformState && typeof transformState === 'function') {
        newState = transformState(stateRef.current, newData);
      }
      setState({ ...stateRef.current, ...newState });
      stateRef.current = { ...stateRef.current, ...newState };
    }
  };

  return [state, dispatch];
};

export default useAsyncReducer;
