function reducer(state, action) {
  switch (action.type) {
    case 'setVisited': {
      const { pageId } = action;
      state[pageId] = true;
      return state;
    }
    default:
      return state;
  }
}

export default reducer;
