// https://github.com/kankomi/async-reducer/blob/master/src/context/CounterContext/index.js

import React, { useCallback } from 'react';
import createActions from './createActions';
import useAsyncReducer from '../../hooks/useAsyncReducer';
import reducer, { initialState } from './reducer';
import LazyLoadError from './LazyLoadError';

export const LazyLoadContext = React.createContext();

export const LazyLoadProvider = ({ children }) => {
  const transformState = useCallback((oldState, newData) => {
    const newState = {
      _status: oldState._status,
      _error: oldState._error,
    };

    newData.forEach((data) => {
      if ({}.toString.call(data.module) !== '[object Module]') {
        throw new LazyLoadError(data.key, 'Error loading a module');
      }
      newState[data.key] = data.module[data.name];
      newState._status[data.key] = 'success';
      newState._error[data.key] = null;
    });
    return newState;
  }, []);

  const transformStateOnError = useCallback((oldState, error) => {
    const newState = oldState;

    if (error.lazyLoadModuleKey) {
      newState[error.lazyLoadModuleKey] = null;
      newState._status[error.lazyLoadModuleKey] = 'error';
      newState._error[error.lazyLoadModuleKey] = error;
    }
    return newState;
  }, []);

  const [asyncState, dispatch] = useAsyncReducer(
    reducer,
    initialState,
    transformState,
    transformStateOnError
  );

  const actions = createActions(dispatch);

  return (
    <LazyLoadContext.Provider value={[asyncState, actions]}>
      {children}
    </LazyLoadContext.Provider>
  );
};
