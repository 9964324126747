import React from 'react';

import '@fontsource/yellowtail/400.css';
import '@fontsource/montserrat/latin-400-italic.css';
import '@fontsource/montserrat/latin-400.css';
import '@fontsource/montserrat/latin-500.css';
import '@fontsource/montserrat/latin-700.css';
// import '@fontsource/montserrat/latin-ext-400-italic.css';
// import '@fontsource/montserrat/latin-ext-400.css';
// import '@fontsource/montserrat/latin-ext-500.css';
// import '@fontsource/montserrat/latin-ext-700.css';

import { LazyLoadProvider } from './src/context/lazyLoadedJs';
import PageVisitedProvider from './src/context/pageVisited';

export const wrapRootElement = ({ element }) => {
  return (
    <PageVisitedProvider>
      <LazyLoadProvider>{element}</LazyLoadProvider>
    </PageVisitedProvider>
  );
};
