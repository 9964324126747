export const initialState = {
  _status: {},
  _error: {},
};

function reducer(state, stateRefCurrent, action) {
  switch (action.type) {
    case 'load':
      if (typeof window !== `undefined`) {
        const promises = [];
        action.moduleKeys.forEach((key) => {
          switch (key) {
            case 'gsap':
              if (stateRefCurrent[key]) return;
              promises.push(
                new Promise((resolve, reject) => {
                  const name = 'default';
                  import('gsap')
                    .then((data) => {
                      resolve({
                        module: data,
                        key,
                        name,
                      });
                    })
                    .catch((err) => {
                      reject(err);
                    });
                })
              );
              break;
            case 'gsapScrollTo':
              if (stateRefCurrent[key]) return;
              promises.push(
                new Promise((resolve, reject) => {
                  const name = 'ScrollToPlugin';
                  import('gsap/ScrollToPlugin')
                    .then((data) => {
                      resolve({
                        module: data,
                        key,
                        name,
                      });
                    })
                    .catch((err) => {
                      reject(err);
                    });
                })
              );
              break;
            case 'kutejs':
              if (stateRefCurrent[key]) return;
              promises.push(
                new Promise((resolve, reject) => {
                  const name = 'default';
                  import('kute.js')
                    .then((data) => {
                      resolve({
                        module: data,
                        key,
                        name,
                      });
                    })
                    .catch((err) => {
                      reject(err);
                    });
                })
              );
              break;
            case 'intersectionObserverPolyfill':
              if (stateRefCurrent[key]) return;
              promises.push(
                new Promise((resolve, reject) => {
                  const name = 'default';
                  import('intersection-observer')
                    .then((data) => {
                      resolve({
                        module: data,
                        key,
                        name,
                      });
                    })
                    .catch((err) => {
                      reject(err);
                    });
                })
              );
              break;

            default:
              break;
          }
        });
        return Promise.all(promises);
      }
      return state;
    default:
      return state;
  }
}

export default reducer;
