function createActions(dispatch) {
  return {
    setVisited: (pageId) => {
      const actionType = 'setVisited';
      return dispatch({ type: actionType, pageId });
    },
  };
}

export default createActions;
