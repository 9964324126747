module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Robert Górnicki - portfolio","short_name":"RG site","description":"Join me on the great adventure with programming the web on my personal site","background_color":"#FFFFFF","theme_color":"#073A73","display":"standalone","icon":"content/favicon/logo_square.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d000864fb6eb523de89bcff1c8267d1f"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"withWebp":true,"showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-code-titles","options":{"className":"gatsby-code-title"}},"gatsby-remark-pre-content",{"resolve":"gatsby-remark-images","options":{"maxWidth":1000,"withWebp":true}},{"resolve":"@weknow/gatsby-remark-twitter","options":{"align":"center"}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1em"}},"gatsby-remark-smartypants","gatsby-remark-copy-linked-files","gatsby-remark-heading-slug",{"resolve":"gatsby-remark-vscode","options":{"theme":"Default Dark+","inlineCode":{"marker":"•"}}}],"remarkPlugins":[null,null],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/robertg042/projects/portfolio-gatsby"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
