function createActions(dispatch) {
  return {
    load: (moduleKeys) => {
      const actionType = 'load';
      return dispatch({ type: actionType, moduleKeys });
    },
  };
}

export default createActions;
