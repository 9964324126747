import React, { useReducer } from 'react';
import createActions from './createActions';
import reducer from './reducer';

export const PageVisitedContext = React.createContext({});

const PageVisitedProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});

  const actions = createActions(dispatch);

  return (
    <PageVisitedContext.Provider value={[state, actions]}>
      {children}
    </PageVisitedContext.Provider>
  );
};

export default PageVisitedProvider;
